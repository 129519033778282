import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatSort, MatPaginator, MatDialog, MatSnackBar } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { DashboardService } from 'src/app/dashboard/dashboard.service';
import { ReportService } from '../../report.service';
import { environment } from 'src/environments/environment';
 
@Component({
  selector: 'app-student-usage-detail',
  templateUrl: './student-usage-detail.component.html',
  styleUrls: ['./student-usage-detail.component.css']
})
export class StudentUsageDetailComponent implements OnInit {

  //@ViewChild(MatPaginator) paginator: MatPaginator;
  //@ViewChild(MatSort) sort: MatSort;
  displayedColumns = ['analytics_type', 'usage'];
  school_id: any;
  class_id: any;
  section_id: any;
  student_id: any;
  studentName: any;
  dataSource: any;
  usageDataList: [];
  school_name: any;
  class_name: any;
  tempObj: { school_id: number; class_id: number; section_id: number; };
  section_name: any;
  usage_tab: boolean = false;
  searchFilter: any = {
    school_id: [],
    class_id: [],
    section_id: []
  };
  liveUrl = environment.liveUrl;
  loading: boolean = false;
  totalUsage: any;
  disableDownload: boolean = true;
  from_date: any;
  to_date: any;


  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private dashboardService: DashboardService,
    private reportService: ReportService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    this.route.params.subscribe(
      params => {
        this.school_id = +params['school_id'];
        this.class_id = +params['class_id'];
        this.section_id = +params['section_id'];
        this.student_id = +params['student_id'];
      }
    );
    this.route.queryParams.subscribe((paramsData: any) => {
     // console.log(paramsData)
      if (paramsData.usage_report == 'Yes') {
        this.usage_tab = true;
        this.searchFilter = paramsData.searchFilter;
        this.from_date = JSON.parse(this.searchFilter).from_date;
        this.to_date = JSON.parse(this.searchFilter).to_date;

        // Set from_date
        if (this.from_date !== '') {
          const timestamp_from_date = new Date(this.from_date).getTime();
          const adjustedFromDate = new Date(timestamp_from_date);
          const month = adjustedFromDate.getMonth() + 1;
          const day = adjustedFromDate.getDate();
          const year = adjustedFromDate.getFullYear();
          const isoStringFromDate = `${month < 10 ? '0' + month : month}/${day < 10 ? '0' + day : day}/${year}`;
          this.from_date = isoStringFromDate;
        } else {
          this.from_date = '';
        }

        // Set to_date
        if (this.to_date !== '') {
          const timestamp_to_date = new Date(this.to_date).getTime();
          const adjustedToDate = new Date(timestamp_to_date);
          const month = adjustedToDate.getMonth() + 1;
          const day = adjustedToDate.getDate();
          const year = adjustedToDate.getFullYear();
          const isoStringToDate = `${month < 10 ? '0' + month : month}/${day < 10 ? '0' + day : day}/${year}`;
          this.to_date = isoStringToDate;
        } else {
          this.to_date = '';
        }
        /*
        //Adding 1 day to from_date
        let tDate = new Date(this.from_date);
        tDate.setDate(tDate.getDate() + 1);
        let tDateMonth = (tDate.getMonth() + 1 < 10) ? '0'+(tDate.getMonth() + 1) : (tDate.getMonth() + 1);
        let tDateDay = (tDate.getDate() < 10) ? '0'+tDate.getDate() : tDate.getDate();
        this.from_date = tDateMonth + "/" + tDateDay + "/" + tDate.getFullYear();
        */       
        
      } else {
          this.usage_tab = false;
      }
    })

    //console.log(this.student_id)
    this.getStudentUsageData();
    this.breadcrumbs();
  }


  breadcrumbs() {
    this.tempObj = {
      school_id: this.school_id,
      class_id: this.class_id,
      section_id: this.section_id
    }

    // get School Name
    this.dashboardService.getSchoolById(this.tempObj).subscribe((schoolData: any) => {
      //console.log(schoolData);
      this.school_name = schoolData.data.school_name;
    })

    // get Class Name
    this.dashboardService.getClass(this.class_id).subscribe((classData: any) => {
      // console.log(classData);
      this.class_name = classData.data.class_name;
    })

    // get Section Name
    this.dashboardService.getSection(this.tempObj).subscribe((sectionData: any) => {
      // console.log(sectionData);
      this.section_name = sectionData.data.class_section.section_name;
    })

  }


  getStudentUsageData() {
    this.loading = true;
    this.disableDownload = true;
    let obj1 = {
      student_id: this.student_id
    }
    this.dashboardService.getStudentDataInfo(obj1).subscribe((studentData: any) => {
      //console.log(studentData);
      this.studentName = studentData.data.studentName;
    })
    
    let obj2 = {
      class_id: this.class_id.toString(),
      quiz_user_id: this.student_id,
      from_date: this.from_date,
      to_date: this.to_date,
    }
    //console.log('obj2 ',obj2);
    this.reportService.getScreenUsage(obj2).subscribe((usageData: any) => {
       //console.log('usageData',usageData);
       if( usageData.data.length !== 0){
        this.usageDataList = usageData.dataKeyVal;
        this.totalUsage = usageData.totalUsage;
        this.dataSource = new MatTableDataSource(this.usageDataList);
       } else {
        this.loading = false;
        this.openSnackBar('No data found', 'Close');

       }
       this.loading = false;
       this.disableDownload = false;
       //console.log('thisdataSource',this.usageDataList);
       
      //this.dataSource.paginator = this.paginator;
      //this.dataSource.sort = this.sort;
    }, (error) => {
      this.loading = false;
      this.openSnackBar('An error occurred.', 'Close');
      console.log('An error occurred:', error);
    }
    )
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2500,
    });
  }


}
