import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatSort, MatPaginator, MatDialog, MatSnackBar } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { DashboardService } from 'src/app/dashboard/dashboard.service';
import { environment } from 'src/environments/environment';
import { PrePostTestsDataComponent } from '../pre-post-tests-data/pre-post-tests-data.component';

@Component({
  selector: 'app-post-student-lesson-list',
  templateUrl: './post-student-lesson-list.component.html',
  styleUrls: ['./post-student-lesson-list.component.css']
})
export class PostStudentLessonListComponent implements OnInit { 

  //@ViewChild(MatPaginator) paginator: MatPaginator;
  //@ViewChild(MatSort) sort: MatSort;
  displayedColumns = ['lesson_name', 'pre_test_completion_time', 'pre_test_score', 'post_test_completion_time', 'post_test_score', 'usage', 'progress'];
  school_id: any;
  class_id: any;
  section_id: any;
  student_id: any;
  studentName: any;
  subjects: any[];
  currentSelSubjectId: any;
  dataSource: any;
  lessonDataList: [];
  user_subjectids: [];
  preTest: boolean = false;
  school_name: any;
  class_name: any;
  tempObj: { school_id: number; class_id: number; section_id: number; };
  section_name: any;
  usage_tab: boolean = false;
  searchFilter: any = {
    school_id: [],
    class_id: [],
    section_id: []
  };
  liveUrl = environment.liveUrl;
  loading: boolean = false;
  totalUsage: any;
  disableDownload: boolean = true;
  class_dash: boolean = false;
  section_dash: boolean = false;
  student_dash: boolean = false;
  defaultTab =  1;
  usageDash: boolean;
  from_date: any;
  to_date: any;


  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private dashboardService: DashboardService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    this.route.params.subscribe(
      params => {
        this.school_id = +params['school_id'];
        this.class_id = +params['class_id'];
        this.section_id = +params['section_id'];
        this.student_id = +params['student_id'];
      }
    );
    this.route.queryParams.subscribe((paramsData: any) => {
     // console.log(paramsData)
     if( paramsData.postTest == '1'){
      this.defaultTab = 1
      this.usageDash = false;
    }else{
      this.usageDash = true
    }
      if (paramsData.usage_report == 'Yes') {
        this.usage_tab = true;
        this.searchFilter = paramsData.searchFilter;
        this.from_date = JSON.parse(this.searchFilter).from_date;
        this.to_date = JSON.parse(this.searchFilter).to_date;
        // console.log(this.searchFilter);
        if (paramsData.dash == 'class_dash') {
          this.class_dash = true;
          this.searchFilter = paramsData.searchFilter;
          // console.log(this.searchFilter);
        }
        if (paramsData.dash == 'section_dash') {
          this.section_dash = true;
          this.searchFilter = paramsData.searchFilter;
          // console.log(this.searchFilter);
        }
        if (paramsData.dash == 'student_dash') {
          this.student_dash = true;
          this.searchFilter = paramsData.searchFilter;
          // console.log(this.searchFilter);
        }
        
      } else {
          this.usage_tab = false;
      }
    })

    //console.log(this.student_id)
    this.getStudentDataList();
    this.breadcrumbs();
  }


  breadcrumbs() {
    this.tempObj = {
      school_id: this.school_id,
      class_id: this.class_id,
      section_id: this.section_id
    }

    // get School Name
    this.dashboardService.getSchoolById(this.tempObj).subscribe((schoolData: any) => {
      //console.log(schoolData);
      this.school_name = schoolData.data.school_name;
    })

    // get Class Name
    this.dashboardService.getClass(this.class_id).subscribe((classData: any) => {
      // console.log(classData);
      this.class_name = classData.data.class_name;
    })

    // get Section Name
    this.dashboardService.getSection(this.tempObj).subscribe((sectionData: any) => {
      // console.log(sectionData);
      this.section_name = sectionData.data.class_section.section_name;
    })

  }


  getStudentDataList() {
    this.loading = true;
    this.disableDownload = true;
    let obj1 = {
      student_id: this.student_id
    }
    this.dashboardService.getStudentDataInfo(obj1).subscribe((studentData: any) => {
      //console.log(studentData);
      this.studentName = studentData.data.studentName;
    })
    let obj2 = {
      class_id: this.class_id,
      quiz_user_id: this.student_id,
      //subject_id: '15'
    }
    this.dashboardService.getUserLessonPerformance(obj2).subscribe((lessonData: any) => {
       //console.log(lessonData);
       if( lessonData.user_subject.length !== 0){
        let subjectList = [];
        for (var i = 0; i < lessonData.user_subject.length; i++) {
          let tempArr = [];
          tempArr['subject_name'] = lessonData.user_subject[i];
          tempArr['id'] = lessonData.user_subjectids[i];
          subjectList.push(tempArr);
        }
        this.subjects = subjectList;
        if (subjectList.length > 0) {
          this.currentSelSubjectId = subjectList[0].id;
          this.changeSubject(this.currentSelSubjectId);
        }
        this.lessonDataList = lessonData.pre_post_test;
        this.dataSource = new MatTableDataSource(this.lessonDataList);
       } else {
        this.loading = false;
        this.openSnackBar('Subject Data Not Found the User !!!', 'Close');

       }
       
      //this.dataSource.paginator = this.paginator;
      //this.dataSource.sort = this.sort;
    }, (error) => {
      this.loading = false;
      this.openSnackBar('An error occurred.', 'Close');
      console.log('An error occurred:', error);
    }
    )
  }

  changeSubject(subject_id) {
    this.loading = true;
    this.disableDownload = true;
    //console.log('thissearchFilter',this.searchFilter);
    let obj = {
      class_id: this.class_id,
      quiz_user_id: this.student_id,
      subject_id: subject_id,
      from_date: this.from_date,
      to_date: this.to_date
    }
    this.totalUsage = '';
    //console.log(obj);
    this.dataSource = new MatTableDataSource();
    this.dashboardService.getUserLessonPerformance(obj).subscribe((lessonData: any) => {
     // console.log(lessonData);  
      this.lessonDataList = lessonData.pre_post_test;
      this.dataSource = new MatTableDataSource(this.lessonDataList);
      this.totalUsage = this.dataSource.data.reduce((acc, curr) => acc + curr.usage, 0);
      //this.dataSource.paginator = this.paginator;
      //this.dataSource.sort = this.sort;
      this.loading = false;
      this.disableDownload = false;
    })

  }

  openPreLessonTests(lesson_id: number, subject_id: number) {
    let obj = {
      class_id: this.class_id,
      quiz_user_id: this.student_id,
      subject_id: subject_id,
      lesson_id: lesson_id
    }
    let preTest = true;
    this.dialog.open(PrePostTestsDataComponent, { data: { obj, preTest } });
  }

  openPostLessonTests(lesson_id: number, subject_id: number) {
    let obj = {
      class_id: this.class_id,
      quiz_user_id: this.student_id,
      subject_id: subject_id,
      lesson_id: lesson_id
    }
    let preTest = false;
    this.dialog.open(PrePostTestsDataComponent, { data: { obj, preTest } });
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2500,
    });
  }


}
