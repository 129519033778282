import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../shared/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ReportComponent } from './report.component';
import { PollReportComponent } from './poll-report/poll-report.component';
import { PollChartComponent } from './poll-chart/poll-chart.component';
import { ChartsModule } from 'ng2-charts';
import { AuthGuard } from '../_guard/auth.guard';
import { PushNotificationComponent } from './push-notification/push-notification.component';
import { TeacherAttenReportComponent } from './teacher-atten-report/teacher-atten-report.component';
import { StudentListComponent } from './teacher-atten-report/student-list/student-list.component';
import { StudentAttenReportComponent } from './student-atten-report/student-atten-report.component';
import { MatTableExporterModule } from 'mat-table-exporter';
import { MsReportComponent } from './ms-report/ms-report.component';
import { UsageReportComponent } from './usage-report/usage-report.component';
import { DayWiseUserReportComponent } from './day-wise-user-report/day-wise-user-report.component';
import { QuizPerformanceReportComponent } from './quiz-performance-report/quiz-performance-report.component';
import { StudentQuizChartComponent } from '../student-quiz-set/student-quiz-chart/student-quiz-chart.component';
import { StudentQuizPerformanceReportComponent } from './student-quiz-performance-report/student-quiz-performance-report.component';
import { QuizTopicPerformanceReportComponent } from './quiz-topic-performance-report/quiz-topic-performance-report.component';
import { QuizUsersListDataComponent } from './quiz-users-list-data/quiz-users-list-data.component';
import { FunQuizDashboardComponent } from './fun-quiz-dashboard/fun-quiz-dashboard.component';
import { StudentTopicUsageComponent } from './student-topic-usage/student-topic-usage.component';
import { ScreenWiseUsageComponent } from './student-topic-usage/screen-wise-usage/screen-wise-usage.component';
import { DownloadUserDetailComponent } from './download-user-detail/download-user-detail.component';
import { StudentUsageDetailComponent } from './usage-report/student-usage-detail/student-usage-detail.component';
import { StudentChapterwiseTestComponent } from './student-chapterwise-test/student-chapterwise-test.component';
import { WeeklyUsageReportComponent } from './weekly-usage-report/weekly-usage-report.component';
import { WeeklyUsageReportLessonComponent } from './weekly-usage-report-lesson/weekly-usage-report-lesson.component';


const routes = [
  {
    path: 'notification-report',
    component: PushNotificationComponent
  },
  {
    path: 'pollReports',
    component: PollReportComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'pollReports/:schedule_id',
    component: PollReportComponent
  },
  {
    path: 'teacherAttendanceReport',
    component: TeacherAttenReportComponent
  },
  {
    path: 'studentAttendanceReport',
    component: StudentAttenReportComponent
  },
  {
    path: 'msReport',
    component: MsReportComponent
  },
  {
    path: 'usageReport',
    component: UsageReportComponent
  },
  {
    path: 'dayWiseReport',
    component: DayWiseUserReportComponent
  },
  {
    path: 'quizPerformanceReport',
    component: QuizPerformanceReportComponent
  },
  {
    path: 'quizTopicPerformanceReport',
    component: QuizTopicPerformanceReportComponent
  },
  {
    path: 'studentQuizPerformanceReport',
    component: StudentQuizPerformanceReportComponent
  },
  {
    path: 'funQuizDashboard',
    component: FunQuizDashboardComponent
  },
  {
    path: 'studentTopicUsage',
    component: StudentTopicUsageComponent
  },
  {
    path: 'downloadUserDetail',
    component: DownloadUserDetailComponent
  },
  {
    path: 'studentUsageDetail/:school_id/:class_id/:section_id/:student_id',
    component: StudentUsageDetailComponent
  },
  {
    path: 'studentChapterwiseTestComponent',
    component: StudentChapterwiseTestComponent
  },
  {
    path: 'weeklyUsageReportComponent',
    component: WeeklyUsageReportComponent
  },
  {
    path: 'weeklyUsageReportLessonComponent',
    component: WeeklyUsageReportLessonComponent
  }
];


@NgModule({
  declarations: [
    ReportComponent,
    PollReportComponent,
    PollChartComponent,
    PushNotificationComponent,
    TeacherAttenReportComponent,
    StudentListComponent,
    StudentAttenReportComponent,
    MsReportComponent,
    UsageReportComponent,
    DayWiseUserReportComponent,
    QuizPerformanceReportComponent,
    QuizTopicPerformanceReportComponent,
    StudentQuizPerformanceReportComponent,
    QuizUsersListDataComponent,
    FunQuizDashboardComponent,
    StudentTopicUsageComponent,
    ScreenWiseUsageComponent,
    DownloadUserDetailComponent,
    StudentUsageDetailComponent,
    StudentChapterwiseTestComponent,
    WeeklyUsageReportComponent,
    WeeklyUsageReportLessonComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule.forChild(routes),
    ChartsModule,
    MatTableExporterModule
  ],
  entryComponents: [StudentListComponent, QuizUsersListDataComponent]
})
export class ReportModule { }
