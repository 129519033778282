import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatSort, MatPaginator, MatDialog, MatSnackBar } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { DashboardService } from 'src/app/dashboard/dashboard.service';
import { environment } from 'src/environments/environment';
import { UserService } from 'src/app/user/user.service';
//import { PrePostTestsDataComponent } from '../../../../pre-post-tests-data/pre-post-tests-data.component';

@Component({
  selector: 'app-student-chapterwise-test',
  templateUrl: './student-chapterwise-test.component.html',
  styleUrls: ['./student-chapterwise-test.component.css']
})
export class StudentChapterwiseTestComponent implements OnInit { 

  //@ViewChild(MatPaginator) paginator: MatPaginator;
  //@ViewChild(MatSort) sort: MatSort;
  displayedColumns = ['lesson_name', 'pre_test_completion_time', 'pre_test_score', 'post_test_score1', 'post_test_score2', 'post_test_score3', 'post_test_score4', 'post_test_score5', 'post_test_best_score'];
  school_id: any;
  class_id: any;
  section_id: any;
  student_id: any;
  studentName: any;
  subjects: any[];
  currentSelSubjectId: any;
  dataSource: any;
  lessonDataList: [];
  user_subjectids: [];
  preTest: boolean = false;
  school_name: any;
  class_name: any;
  tempObj: { school_id: number; class_id: number; section_id: number; };
  section_name: any;
  usage_tab: boolean = false;
  /*searchFilter: any = {
    school_id: [],
    class_id: [],
    section_id: []
  };*/
  searchFilter: any = {
    phone: '',
    email: '',
    school_id: [],
    class_id: [],
    section_id: [],
    user_type_id: '',
    from_date: '',
    to_date: ''
  };
  liveUrl = environment.liveUrl;
  loading: boolean = false;
  totalUsage: any;
  disableDownload: boolean = true;
  class_dash: boolean = false;
  section_dash: boolean = false;
  student_dash: boolean = false;
  defaultTab =  1;
  usageDash: boolean;
  from_date: any;
  to_date: any;
  
  currentUser: any;
  userId: any;
  type_order: any;
  schoolList: any;
  classList: any;
  schoolArr = [];
  classArr = [];
  sectionArr: [];
  classObjArr = [];
  sectionList: any;
  selectedSchoolName: string;
  userTypes: any;
  suggestEmail: any;


  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private dashboardService: DashboardService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));

   //.log(this.currentUser)
    //console.log(this.filterData)
    
    if (this.currentUser) {
      this.userId = this.currentUser.adminUser[0].id;
      this.type_order = this.currentUser.adminUser[0].user_Type.type_order;
    }
    if (this.type_order == 1 || this.type_order == 2) {
      this.userService.getActiveSchools(this.userId).subscribe((data: any) => {
        this.schoolList = data.data;
        this.schoolList.forEach(element => { this.schoolArr.push(element.id) });
        this.getUserClasses();
      });
    } else {
      let obj = { user_id: this.userId }
      this.userService.userSchools(obj).subscribe((data: any) => {
        this.schoolList = data.data;
        this.schoolList.forEach(element => { this.schoolArr.push(element.id) });
        this.classArr = data.userClass;
        this.sectionArr = data.userSection;
        this.getUserClasses();
      });
    }

    this.userService.getUserTypes(this.userId).subscribe((data) => {
      this.userTypes = data;
     // console.log(this.userTypes)
    });

    
    this.route.params.subscribe(
      params => {
        this.school_id = +params['school_id'];
        this.class_id = +params['class_id'];
        this.section_id = +params['section_id'];
        this.student_id = +params['student_id'];
      }
    );
    this.route.queryParams.subscribe((paramsData: any) => {
     // console.log(paramsData)
     
      if (paramsData.searchFilter) {
        this.loading = true;
        this.searchFilter = paramsData.searchFilter;
        this.from_date = JSON.parse(this.searchFilter).from_date;
        this.to_date = JSON.parse(this.searchFilter).to_date;
      }
        
      
    })

    //console.log(this.student_id)
    //this.getStudentDataList(this.searchFilter);
  }

  getStudentDataList(filters: any) {
    this.disableDownload = true;
    this.loading = true;
    
    this.searchFilter = filters;
    if(this.searchFilter.email != '') {
      //console.log('filter',this.searchFilter);
      let obj1 = {
        email: this.searchFilter.email
      }
      //console.log(obj1);
      this.dashboardService.getStudentDataInfo(obj1).subscribe((studentData: any) => {
        //console.log('studentData',studentData.data);
        //console.log('stuname', studentData.data.user_id+"  =>  "+studentData.data.studentName);
        this.studentName = studentData.data.studentName;
        this.student_id = studentData.data.user_id;
      
      //console.log('stuname11', this.student_id+"  =>  "+this.studentName);
      let obj2 = {
        class_id: this.searchFilter.class_id, //this.class_id,
        quiz_user_id: this.student_id
        //subject_id: '15'
      }
      //console.log('obj2', obj2);
      this.dashboardService.getUserLessonPerformance(obj2).subscribe((lessonData: any) => {
        //console.log(lessonData);
       if( lessonData.user_subject.length !== 0){
          let subjectList = [];
          for (var i = 0; i < lessonData.user_subject.length; i++) {
            let tempArr = [];
            tempArr['subject_name'] = lessonData.user_subject[i];
            tempArr['id'] = lessonData.user_subjectids[i];
            subjectList.push(tempArr);
          }
          this.subjects = subjectList;
          if (subjectList.length > 0) {
            this.currentSelSubjectId = subjectList[0].id;
            this.changeSubject(this.currentSelSubjectId);
          }

          this.lessonDataList = lessonData.pre_post_test;
          //console.log('thislessonDataList',this.lessonDataList);
        
          this.dataSource = new MatTableDataSource(this.lessonDataList);
          //console.log('thisdataSource',this.dataSource);
        } else {
          this.loading = false;
          this.openSnackBar('Subject Data Not Found the User !!!', 'Close');

        }
        
        //this.dataSource.paginator = this.paginator;
        //this.dataSource.sort = this.sort;
      }, (error) => {
        this.loading = false;
        this.openSnackBar('An error occurred.', 'Close');
        console.log('An error occurred:', error);
      })
    })
    }
  }

  changeSubject(subject_id) {
    this.loading = true;
    this.disableDownload = true;
    //console.log('thissearchFilter',this.searchFilter);
    /*let obj2 = {
      class_id: this.searchFilter.class_id, //this.class_id,
      quiz_user_id: this.student_id
      //subject_id: '15'
    }*/
    let obj = {
      class_id: this.searchFilter.class_id,
      quiz_user_id: this.student_id,
      subject_id: subject_id,
      from_date: this.from_date,
      to_date: this.to_date
    }
    this.totalUsage = '';
    //console.log('obj_sybshange',obj);
    this.dataSource = new MatTableDataSource();
    this.dashboardService.getUserLessonPerformance(obj).subscribe((lessonData: any) => {
     // console.log(lessonData);  
      this.lessonDataList = lessonData.pre_post_test;
      //console.log('thislessonDataList_subchange', this.lessonDataList); 
      this.dataSource = new MatTableDataSource(this.lessonDataList);
      this.totalUsage = this.dataSource.data.reduce((acc, curr) => acc + curr.usage, 0);
      //this.dataSource.paginator = this.paginator;
      //this.dataSource.sort = this.sort;
      this.loading = false;
      this.disableDownload = false;
    })

  }

  openPreLessonTests(lesson_id: number, subject_id: number) {
    let obj = {
      class_id: this.class_id,
      quiz_user_id: this.student_id,
      subject_id: subject_id,
      lesson_id: lesson_id
    }
    let preTest = true;
    //this.dialog.open(PrePostTestsDataComponent, { data: { obj, preTest } });
  }

  openPostLessonTests(lesson_id: number, subject_id: number) {
    let obj = {
      class_id: this.class_id,
      quiz_user_id: this.student_id,
      subject_id: subject_id,
      lesson_id: lesson_id
    }
    let preTest = false;
    //this.dialog.open(PrePostTestsDataComponent, { data: { obj, preTest } });
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2500,
    });
  }

  getUserClasses() {
    let classObj = {
      board_id: 1,
      class_id: this.classArr
    }
    this.userService.getClasses(classObj).subscribe((data: any) => {
      this.classList = data.data;
      //console.log('classList',this.classList);
    });
  }

  onSchoolSelected(id) {
    // console.log(id)
    const selectedSchool = this.schoolList.find((school: any) => school.id === id) as { id: number, school_name: string };
    this.selectedSchoolName = selectedSchool ? selectedSchool.school_name : '';
   // console.log(this.selectedSchoolName)
  }

  changeClass(class_id, callback) {
    // console.log(class_id)
     this.searchFilter.section_id = '';
     let classObj = { class_id: [class_id], section_id: this.sectionArr };
     this.userService.getClassSections(classObj).subscribe((result: any) => {
       this.sectionList = result.data;
       if (callback) {
         callback();
       }
     });
   }

   emailFilter(filterValue: string) {
    //this.errorMessage = {};
    if (filterValue.length > 4) {
      let obj = { email: filterValue};
      //console.log(obj)
      this.userService.getSuggestData(obj).subscribe((emailData: any) => {
       // console.log(emailData);
        this.suggestEmail = emailData.data;
        // console.log(filterValue.indexOf('@'),  this.suggestData.length);
        // if (filterValue.indexOf('@') > 0 && this.suggestEmail.length <= 0) {
        //   this.errorMessage.message = "User is not registered"
        // }
      });
    }
  }

  fillData(suggest) {
    this.searchFilter.email = suggest.email;
  }


  reset() {
    this.loading = false;
    this.searchFilter = {
      phone: '',
      email: '',
      school_id: [],
      class_id: [],
      section_id: [],
      user_type_id: '',
      from_date: '',
      to_date: ''
    };
    this.disableDownload = true;
    this.dataSource = new MatTableDataSource();
    this.sectionList = [];
  }


}