import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSnackBar, MatSort, MatTableDataSource } from '@angular/material';
import {  ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { UserService } from 'src/app/user/user.service';
import { environment } from 'src/environments/environment';
import { DashboardService } from 'src/app/dashboard/dashboard.service';
import { MatTableExporterModule } from 'mat-table-exporter';
import { Subscription } from 'rxjs';
import { QuestionService } from 'src/app/question/question.service';

@Component({
  selector: 'app-weekly-usage-report',
  templateUrl: './weekly-usage-report.component.html',
  styleUrls: ['./weekly-usage-report.component.css']
})
export class WeeklyUsageReportComponent implements OnInit {
  //@Input() filterData;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  //displayedColumns = ['userName', 'email', 'contact_no', 'user_last_login', 'total_quiz_attempt', 'total_attempted', 'total_correct', 'total_incorrect', 'total_skipped', 'percentage', 'total_usage', 'lesson_usage'];
  displayedColumns = ['userName', 'email', 'week_total_usage_math', 'week_total_usage_science', 'week_content_usage_math', 'week_content_usage_science', 'week_revtool_usage_math', 'week_revtool_usage_science', 'week_testprep_usage_math', 'week_testprep_usage_science', 'week_formative_usage_math', 'week_formative_usage_science', 'week_notes_usage_math', 'week_notes_usage_science', 'week_glossary_usage_math', 'week_glossary_usage_science', 'week_solvedquestions_usage_math', 'week_solvedquestions_usage_science', 'week_quiz_usage_math', 'week_quiz_usage_science', 'latest_quiz_score_math', 'latest_quiz_score_science'];
  pageTitle = 'Weekly Usage Report';
  dataSource: any;
  school_id: any;
  class_id: any;
  section_id: any;
  subjects: any;
  lessons: any;
  topics: any;
  limit = 12;
  offset = 0;
  customFilters = {}
  studentList: any;
  section_name: string;
  student_id: any;
  currentUser: any;
  userId: any;
  type_order: any;
  schoolList: any;
  classList: any;
  schoolArr = [];
  classArr = [];
  sectionArr: [];
  classObjArr = [];
  sectionList: any;
  searchFilter: any = {
    phone: '',
    email: '',
    school_id: [],
    class_id: [],
    section_id: [],
    subject_id: [],
    lesson_id: [],
    topic_id: [],
    user_type_id: '',
    from_date: this.subtractDays(7),
    to_date: this.subtractDays(0)
  };
  suggestEmail: any;
  errorMessage: any;
  loading: boolean = false;
  liveUrl = environment.liveUrl;
  customFilter: any;
  userTypes: void;
  disableDownload: boolean = true;
  subscription: Subscription;
  school_disable : boolean = false
  class_disable: boolean = false;
  section_disable: boolean = false;
  dash: any;
  user_type_disable: boolean = false;
  selectedSchoolName: string;
  displaySchoolName: boolean = false;
  from_date: any; 
  to_date: any;
  currentDate = new Date();
  
  constructor(
    private route: ActivatedRoute,
    private dashboardService: DashboardService,
    private userService: UserService,
    private router: Router,
    private snackBar: MatSnackBar,
    private questionService: QuestionService,
  ) { }

  ngOnInit() {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));

   //.log(this.currentUser)
    //console.log(this.filterData)
    
    if (this.currentUser) {
      this.userId = this.currentUser.adminUser[0].id;
      this.type_order = this.currentUser.adminUser[0].user_Type.type_order;
    }
    if (this.type_order == 1 || this.type_order == 2) {
      this.userService.getActiveSchools(this.userId).subscribe((data: any) => {
        this.schoolList = data.data;
        this.schoolList.forEach(element => { this.schoolArr.push(element.id) });
        this.getUserClasses();
      });
    } else {
      let obj = { user_id: this.userId }
      this.userService.userSchools(obj).subscribe((data: any) => {
        this.schoolList = data.data;
        this.schoolList.forEach(element => { this.schoolArr.push(element.id) });
        this.classArr = data.userClass;
        this.sectionArr = data.userSection;
        this.getUserClasses();
      });
    }

    this.userService.getUserTypes(this.userId).subscribe((data) => {
      this.userTypes = data;
     // console.log(this.userTypes)
    });

    this.route.queryParams.subscribe((params: any) => {
     // console.log(params)
      if (params.searchFilter) {
        this.loading = true;
        this.searchFilter = JSON.parse(params.searchFilter);
        this.getUserList();
      }
    })
  }

  getUserList(){
      //let curr_date = new Date();
     // Set from_date
     if (this.searchFilter.from_date == '') {
      this.searchFilter.from_date = this.subtractDays(7);
    }
    this.from_date = this.stringToDate(this.searchFilter.from_date);

    // Set to_date
    if (this.searchFilter.to_date == '') {
      this.searchFilter.to_date = this.subtractDays(0);
    }
    this.to_date = this.stringToDate(this.searchFilter.to_date);


    //console.log('fromto date', this.from_date+' ==> '+this.to_date)
    let obj = {
      school_id: this.searchFilter.school_id,
      class_id: this.searchFilter.class_id,
      subject_id: this.searchFilter.subject_id,
      lesson_id: this.searchFilter.lesson_id,
      topic_id: this.searchFilter.topic_id,
      section_id: this.searchFilter.section_id,
      email: this.searchFilter.email,
      mobile: this.searchFilter.phone,
      user_type_id: this.searchFilter.user_type_id,
      start_date: this.from_date,
      end_date: this.to_date
    }
   console.log('obj',obj);
   //console.log('thisto_date',this.to_date);
    this.subscription = this.dashboardService.getWeeklyUserUsage(obj).subscribe((data:any) => {
    //console.log(data);
      let listStudent = data;
      if( data.status !== false){
        if(listStudent.length != 0){
          this.onSchoolSelected(this.searchFilter.school_id);
          this.studentList = listStudent.filter(item => item !== null);
          this.dataSource = new MatTableDataSource(this.studentList);
          this.dataSource.paginator = this.paginator;
          this.displaySchoolName = true;
          this.disableDownload = false;
          //this.dataSource.sort = this.sort;
          
        } else {
          this.displaySchoolName = false;
          this.disableDownload = true;
          this.dataSource = new MatTableDataSource();
          this.errorMessage = data.message;
          this.openSnackBar('User not found. Search again with different criteria !!!', 'Close');
          if (this.subscription) {
            this.subscription.unsubscribe();
          }
        }   
      } else {
        this.errorMessage = data.message;
        this.displaySchoolName = false;
        this.disableDownload = true;
        this.openSnackBar(this.errorMessage, 'Close');
        this.dataSource = new MatTableDataSource();
        this.dataSource.paginator = this.paginator;
        if (this.subscription) {
          this.subscription.unsubscribe();
        }
      }
      this.loading = false;
    },
    (error) => {
      this.loading = false;
      this.openSnackBar('An error occurred.', 'Close');
      console.error('An error occurred:', error);
      if (this.subscription) {
        this.subscription.unsubscribe();
      }
    }
    )
  }

  subtractDays(days: number): Date {
    let date = new Date();
    date.setDate(date.getDate() - days);
    return date;
}

stringToDate(date) {
    const timestamp_from_date = new Date(date).getTime();
    const adjustedFromDate = new Date(timestamp_from_date);
    const monthf = adjustedFromDate.getMonth() + 1;
    const dayf = adjustedFromDate.getDate();
    const yearf = adjustedFromDate.getFullYear();
    const isoStringFromDate = `${monthf < 10 ? '0' + monthf : monthf}/${dayf < 10 ? '0' + dayf : dayf}/${yearf}`;
    return isoStringFromDate;
}
  
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2500,
    });
  }

  onSchoolSelected(id) {
    // console.log(id)
    const selectedSchool = this.schoolList.find((school: any) => school.id === id) as { id: number, school_name: string };
    this.selectedSchoolName = selectedSchool ? selectedSchool.school_name : '';
   // console.log(this.selectedSchoolName)
  }

  openStudentLessonList(school_id, class_id, section_id, user_id) {
    this.student_id = user_id;
    //console.log(this.student_id)

    // this.router.navigate(['/post_student_lesson_list', `${school_id}`, `${class_id}`, `${section_id}`, `${this.student_id}`], 
    // {queryParams: {usage_report: 'Yes', dash: this.dash, searchFilter: JSON.stringify(this.searchFilter)}});
    this.router.navigate(['/post_student_lesson_list', school_id, class_id, section_id, this.student_id], {
      queryParams: { usage_report: 'Yes', dash: this.dash, searchFilter: JSON.stringify(this.searchFilter) }
    });
  }

  openStudentUsageDetail(school_id, class_id, section_id, user_id) {
    this.student_id = user_id;
    //console.log(this.student_id)

    this.router.navigate(['/studentUsageDetail', school_id, class_id, section_id, this.student_id], {
      queryParams: { usage_report: 'Yes', dash: this.dash, searchFilter: JSON.stringify(this.searchFilter) }
    });
  }

  getUserClasses() {
    let classObj = {
      board_id: 1,
      class_id: this.classArr
    }
    this.userService.getClasses(classObj).subscribe((data: any) => {
      this.classList = data.data;
    });
  }

  // changeClass(class_id) {
  //   this.searchFilter.section_id = '';
  //   let classObj = { class_id: [class_id], section_id: this.sectionArr }
  //   //console.log(classObj)
  //   this.userService.getClassSections(classObj).subscribe((result: any) => {
  //     this.sectionList = result.data;
  //     //console.log(this.sectionList)
  //   })
  // }

  changeClass(class_id, callback) {
   // console.log(class_id)
    this.searchFilter.section_id = '';
    let classObj = { class_id: [class_id], section_id: this.sectionArr };
    this.userService.getClassSections(classObj).subscribe((result: any) => {
      this.sectionList = result.data;
      if (callback) {
        callback();
      }
    });

    const paramsVal = { class_id: [class_id] };

    this.questionService.getSubjects(paramsVal).subscribe((result: any) => {
      this.subjects = result.data;
    });
  }

  changeSubject(strVal, defaultVal = 0) {
    this.lessons = [];
    this.topics = [];
    this.searchFilter.lesson_id = [];
    this.searchFilter.topic_id = [];

    const paramsVal = { subject_id: [strVal] };
    this.questionService.getLessons(paramsVal).subscribe((result: any) => {
      this.lessons = result.data;

    });
  }

  changeLesson(strVal, defaultVal = 0) {
    this.topics = [];
    this.searchFilter.topic_id = [];
    const paramsVal = { lesson_id: [strVal] };

    this.questionService.getTopics(paramsVal).subscribe((result: any) => {
      this.topics = result.data;
    });
  }
  

  searchUserData(filters: any) {
    this.searchFilter = filters;
    this.disableDownload = true;
   //console.log('sf',this.searchFilter)
   this.loading = true;
    this.dataSource = new MatTableDataSource();
    this.dataSource.paginator = this.paginator;
    this.getUserList();
  }

  reset() {
    this.loading = false;
    this.searchFilter = {
      phone: '',
      email: '',
      school_id: [],
      class_id: [],
      section_id: [],
      user_type_id: '',
      from_date: '',
      to_date: ''
    };
    this.disableDownload = true;
    this.displaySchoolName = false;
    this.dataSource = new MatTableDataSource();
    this.dataSource.paginator = this.paginator;
    this.sectionList = [];
    // if(this.filterData){
    //   this.searchFilter.school_id = this.filterData.school_id;
    //   this.searchFilter.class_id = this.filterData.class_id;
    //   this.changeClass(this.searchFilter.class_id, '');
    //   this.searchFilter.section_id = this.filterData.section_id;
    //   this.searchFilter.user_type_id = 8;
    //   this.searchFilter.email = '';
    //   this.searchFilter.phone = '';
    // } else {
    //   this.searchFilter = {};
    // }
    //this.getUserList();
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }


  emailFilter(filterValue: string) {
    //this.errorMessage = {};
    if (filterValue.length > 4) {
      let obj = { email: filterValue};
      //console.log(obj)
      this.userService.getSuggestData(obj).subscribe((emailData: any) => {
       // console.log(emailData);
        this.suggestEmail = emailData.data;
        // console.log(filterValue.indexOf('@'),  this.suggestData.length);
        // if (filterValue.indexOf('@') > 0 && this.suggestEmail.length <= 0) {
        //   this.errorMessage.message = "User is not registered"
        // }
      });
    }
  }
  
  fillData(suggest) {
    this.searchFilter.email = suggest.email;
  } 

  // ngOnDestroy() {
  //   this.subscription.unsubscribe();
  // }

}
